import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { L } from '@ic2/ic2-lib';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, ContactService, DataProcessingModalComponent, HijiBundle, Ic2ToastrService } from 'common';

@Component({
  selector: 'hiji-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  email: string;
  message: string = null;
  loading: boolean = false;
  manualHierarchicalManagement: boolean = false;
  constructor(
    private authService: AuthService,
    private contactService: ContactService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private ic2ToastrService: Ic2ToastrService,
    private router: Router,
    private ngbModal: NgbModal
  ) {
    this.email = authService.userBundle.user.email;
    this.manualHierarchicalManagement = (authService.userBundle.data as HijiBundle).modules.moduleGlobal.manualHierarchicalManagement;
  }

  ngOnInit(): void {}

  send() {
    if (this.message === null || this.message === '') return;
    this.loading = true;
    this.contactService.contact(this.route.snapshot.params['slug'], this.message).subscribe(
      (data) => {
        this.loading = false;

        this.ic2ToastrService.showSuccess(
          this.translate.instant('contact.Merci de nous avoir contacté, nous vous répondrons via un mail à votre adresse:') + ' ' + this.email
        );
        this.router.navigate(['/']);
      },
      (err) => {
        L.e(err);
      }
    );
  }

  showPopup() {
    this.ngbModal.open(DataProcessingModalComponent, { centered: true });
  }
}
