<div class="row">
  <div class="col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3 mt-3 animate__animated animate__fadeInLeft">
    <div class="bg-white border rounded-3 p-2">
      <div class="row mt-1">
        <div class="col-12 fw-bold text-center my-fs-md-4">{{ modulesDTO.moduleEMA.emaName | ms }}</div>
      </div>
      @if (ua.isInMyTeam || data.isCoach || me) {
        <div class="mx-3">
          @if (data.nextRDV !== null) {
            <!-- j'ai un EMA planifié et des obs à faire -->
            @if (me) {
              <div class="row mt-2">
                <div class="col">
                  <i class="fa-light fa-calendar me-2"></i>
                  {{ 'user-actions.ema.Prochain rendez-vous le \{\{date\}\}' | translate: { date: data.nextRDV.date | localizedDate } }}
                </div>
              </div>
              <div class="row mt-2">
                @if (needsToObserve()) {
                  <div class="col" *ngIf="">
                    {{
                      'user-actions.ema.Afin de préparer votre rendez-vous, vous devez encore réaliser \{\{nbObs\}\} auto-observations'
                        | translate
                          : {
                              nbObs: data.nextRDV.nbObsToDo - data.nbAutoObsDoneSinceLastEMA,
                            }
                    }}
                  </div>
                } @else {
                  <div class="col text-success">
                    {{ 'user-actions.ema.Félicitations vous avez réalisé toutes vos auto-observations' | translate }}
                  </div>
                }
              </div>
              @if (data.nextRDV.nextAutoObsHint !== null && needsToObserve() && data.allowAutoObs) {
                <div class="row mt-2 justify-content-center">
                  <div class="col-auto">
                    {{
                      'user-actions.ema.Nous vous conseillons de réaliser votre prochaine auto-observation le \{\{date\}\}'
                        | translate
                          : {
                              date: data.nextRDV.nextAutoObsHint | localizedDate,
                            }
                    }}
                  </div>
                </div>
              }
              <div class="row mt-2 justify-content-center">
                @if (data.allowAutoObs) {
                  <div class="col-6 text-center">
                    <span class="badge hiji-bg-auto-observation rounded-circle">{{ data.nbAutoObsDoneSinceLastEMA }}</span>
                    <span class="hiji-text-auto-observation ms-1">{{ 'global.Auto-observation' | translate }}</span>
                  </div>
                }
                <div class="col-6 text-center">
                  <span class="badge hiji-bg-manager rounded-circle">{{ data.nbObsDoneSinceLastEMA }}</span>
                  <span class="hiji-text-manager ms-1">{{ 'global.Observation' | translate }}</span>
                </div>
                @if (data.nbCoachObsDoneSinceLastEMA > 0) {
                  <div class="col-6 text-center">
                    <span class="badge hiji-bg-coach rounded-circle">{{ data.nbCoachObsDoneSinceLastEMA }}</span>
                    <span class="hiji-text-coach ms-1">{{ 'global.Observation coach' | translate }}</span>
                  </div>
                }
              </div>
              @if (data.allowAutoObs) {
                <div class="row mt-2 justify-content-center">
                  <div class="col-auto">
                    <button
                      class="btn hiji-bg-auto-observation animate__animated animate__repeat-3"
                      [class.animate__pulse]="needsToObserve() && DateUtils.isPast(data.nextRDV.nextAutoObsHint)"
                      [class.btn-sm]="!needsToObserve()"
                      [routerLink]="['/ema', 'observe', ua.member.idUser, string_to_slug(ua.member.firstName + ' ' + ua.member.lastName)]"
                    >
                      {{ "user-actions.ema.S'auto-observer" | translate }}
                    </button>
                  </div>
                </div>
              }
            } @else {
              <div class="row mt-2">
                <div class="col">
                  <i class="fa-light fa-calendar me-2"></i>
                  {{ 'user-actions.ema.Prochain rendez-vous le \{\{date\}\}' | translate: { date: data.nextRDV.date | localizedDate } }}
                </div>
              </div>
              @if (data.nextRDV.nextObsHint !== null && needsToObserve()) {
                <div class="row mt-2 justify-content-center">
                  <div class="col-auto">
                    {{
                      'user-actions.ema.Nous vous conseillons de réaliser votre prochaine observation le \{\{date\}\}'
                        | translate
                          : {
                              date: data.nextRDV.nextObsHint | localizedDate,
                            }
                    }}
                  </div>
                </div>
              }
              <div class="row mt-2 justify-content-center">
                <div class="col-6 text-center">
                  <span class="badge hiji-bg-auto-observation rounded-circle">{{ data.nbAutoObsDoneSinceLastEMA }}</span>
                  <span class="hiji-text-auto-observation ms-1">{{ 'global.Auto-observation' | translate }}</span>
                </div>
                <div class="col-6 text-center">
                  <span class="badge bg-primary rounded-circle">{{ data.nbObsDoneSinceLastEMA }}</span>
                  <span class="text-primary ms-1">{{ 'global.Observation' | translate }}</span>
                </div>
                @if (data.nbCoachObsDoneSinceLastEMA > 0) {
                  <div class="col-6 text-center">
                    <span class="badge hiji-bg-coach rounded-circle">{{ data.nbCoachObsDoneSinceLastEMA }}</span>
                    <span class="hiji-text-coach ms-1">{{ 'global.Observation coach' | translate }}</span>
                  </div>
                }
              </div>
              <div class="row mt-3 justify-content-center">
                <div class="col-auto">
                  <button
                    class="btn btn-primary animate__animated animate__repeat-3"
                    [class.btn-primary]="!data.isCoach"
                    [class.btn-warning]="data.isCoach"
                    [class.animate__pulse]="needsToObserve() && DateUtils.isPast(data.nextRDV.nextObsHint)"
                    [class.btn-sm]="!needsToObserve() || data.lastUnfinishedEMA !== null"
                    [routerLink]="['/ema', 'observe', ua.member.idUser, string_to_slug(ua.member.firstName + ' ' + ua.member.lastName)]"
                  >
                    {{ 'user-actions.ema.Observer' | translate }}
                  </button>
                </div>
              </div>
              <div class="row mt-2 justify-content-center">
                <div class="col-auto">
                  <button
                    class="btn animate__animated"
                    [class.btn-primary]="!data.isCoach"
                    [class.btn-warning]="data.isCoach"
                    [class.animate__bounceIn]="!needsToObserve()"
                    [class.btn-sm]="needsToObserve()"
                    [routerLink]="['/ema', ua.member.idUser, string_to_slug(ua.member.firstName + ' ' + ua.member.lastName)]"
                  >
                    {{
                      data.lastUnfinishedEMA !== null
                        ? ('user-actions.ema.Continuer votre rendez-vous' | translate)
                        : ('user-actions.ema.Démarrer le rendez-vous' | translate)
                    }}
                  </button>
                </div>
              </div>
            }
          }

          <!-- j'ai pas replanifié d'EMA -->
          @if (data.nextRDV === null && (data.hadEMAInThePast || data.allowObservationWithoutFirstMeeting)) {
            <div class="row justify-content-center">
              <div class="col-6 text-center">
                <span class="badge hiji-bg-auto-observation rounded-circle">{{ data.nbAutoObsDoneSinceLastEMA }}</span>
                <span class="hiji-text-auto-observation ms-1">{{ 'global.Auto-observation' | translate }}</span>
              </div>
              <div class="col-6 text-center">
                <span class="badge hiji-bg-manager rounded-circle">{{ data.nbObsDoneSinceLastEMA }}</span>
                <span class="hiji-text-manager ms-1">{{ 'global.Observation' | translate }}</span>
              </div>
              @if (data.nbCoachObsDoneSinceLastEMA > 0) {
                <div class="col-6 text-center">
                  <span class="badge hiji-bg-coach rounded-circle">{{ data.nbCoachObsDoneSinceLastEMA }}</span>
                  <span class="hiji-text-coach ms-1">{{ 'global.Observation coach' | translate }}</span>
                </div>
              }
            </div>

            @if (me && data.allowAutoObs) {
              <div class="row mt-2 justify-content-center">
                <div class="col-auto">
                  <button
                    class="btn hiji-bg-auto-observation"
                    [class.btn-sm]="!needsToObserve() || data.lastUnfinishedEMA !== null"
                    [routerLink]="['/ema', 'observe', ua.member.idUser, string_to_slug(ua.member.firstName + ' ' + ua.member.lastName)]"
                  >
                    {{ "user-actions.ema.S'auto-observer" | translate }}
                  </button>
                </div>
              </div>
            }
            @if (!me) {
              <div class="row mt-2 justify-content-center">
                <div class="col-auto">
                  <button
                    class="btn hiji-bg-manager"
                    [class.btn-sm]="!needsToObserve() || data.lastUnfinishedEMA !== null"
                    [routerLink]="['/ema', 'observe', ua.member.idUser, string_to_slug(ua.member.firstName + ' ' + ua.member.lastName)]"
                  >
                    {{ 'user-actions.ema.Observer' | translate }}
                  </button>
                </div>
              </div>
              <div class="row mt-2 justify-content-center">
                <div class="col-auto">
                  <button class="btn btn-primary" [routerLink]="['/ema', ua.member.idUser, string_to_slug(ua.member.firstName + ' ' + ua.member.lastName)]">
                    {{
                      data.lastUnfinishedEMA !== null
                        ? ('user-actions.ema.Continuer votre rendez-vous' | translate)
                        : ('user-actions.ema.Démarrer le rendez-vous' | translate)
                    }}
                  </button>
                </div>
              </div>
            }
          }

          <!-- c'est mon premier EMA -->
          @if (data.nextRDV === null && !data.hadEMAInThePast && !data.allowObservationWithoutFirstMeeting) {
            @if (me) {
              <div class="row mt-2">
                <div class="col">
                  {{ "user-actions.ema.Vous n'avez pas de rendez-vous planifié, demandez à votre manager de planifier un rendez-vous" | translate }}
                </div>
              </div>
            } @else {
              <div class="row mt-2">
                <div class="col">
                  {{
                    "user-actions.ema.C'est votre premier rendez-vous avec \{\{user\}\}" | translate: { user: ua.member.firstName + ' ' + ua.member.lastName }
                  }}
                </div>
              </div>
              <div class="row mt-2 justify-content-center">
                <div class="col-auto">
                  <button class="btn btn-primary" [routerLink]="['/ema', ua.member.idUser, string_to_slug(ua.member.firstName + ' ' + ua.member.lastName)]">
                    {{
                      data.lastUnfinishedEMA !== null
                        ? ('user-actions.ema.Continuer votre rendez-vous' | translate)
                        : ('user-actions.ema.Démarrer le rendez-vous' | translate)
                    }}
                  </button>
                </div>
              </div>
            }
          }
        </div>
      }

      @if (data.hadEMAInThePast) {
        @if (ua.isInMyTeam || data.isCoach || me) {
          <div class="row mt-3">
            <div class="col px-1">
              <hr class="m-0" />
            </div>
          </div>
        }

        <a class="text-body pointer" [routerLink]="['/ema', ua.member.idUser, string_to_slug(ua.member.firstName + ' ' + ua.member.lastName), 'list']">
          <div class="row align-items-center mt-3 mb-2">
            <div class="col-auto">
              <i class="fa-light fa-comments-question-check fa-2x text-primary p-2 align-icon"></i>
            </div>
            <div class="col my-fs-md-4">
              {{ 'user-actions.ema.Accéder aux précédents rendez-vous' | translate }}
            </div>
          </div>
        </a>
      }
    </div>
  </div>
</div>
