<div class="bg-light-30p h-100 fit-height d-flex flex-column">
  <div class="container-fluid overflow-auto flex-grow-1" scrollTracker (scrollingFinished)="onScrollingFinished()">
    <div class="py-3">
      <ng-container *ngFor="let data of allData | orderBy: 'date' : true">
        <div class="row mt-2" *ngIf="data.data.constructor.name === 'FeedbackDTO'">
          <div class="col col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3 pb-3">
            <hiji-feedback-message [feedback]="data.data"></hiji-feedback-message>
          </div>
        </div>
        <div class="row mt-2" *ngIf="data.data.constructor.name === 'VizBilanDTO'">
          <a class="text-body" routerLink="/visualize/restitution/{{ data.data.idBilan }}">
            <div class="col col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3 text-center">
              <hiji-visualize-bilan-card [bilan]="data.data"></hiji-visualize-bilan-card>
            </div>
          </a>
        </div>
        <div class="row mt-2" *ngIf="data.data.constructor.name === 'ExchangeItemDTO'">
          <a
            class="text-body"
            routerLink="/coaching/share/{{ data.data.idUserObserved }}/{{
              string_to_slug(data.data.idUserObserved === idUserLogged ? data.data.userManager : data.data.userObserved)
            }}/{{ data.data.idExchange }}"
          >
            <div class="col col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3">
              <hiji-visualize-exchange-card [exchange]="data.data"></hiji-visualize-exchange-card>
            </div>
          </a>
        </div>
        <div class="row mt-2" *ngIf="data.data.constructor.name === 'VizSurveyDTO'">
          <a class="text-body" routerLink="/visualize/survey/{{ data.data.idCampaign }}/{{ string_to_slug(data.data.name | ms) }}">
            <div class="col col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3 text-center">
              <hiji-visualize-survey-card [survey]="data.data"></hiji-visualize-survey-card>
            </div>
          </a>
        </div>

        <div class="row mt-2" *ngIf="data.data.constructor.name === 'VizInquiryDTO'">
          <a class="text-body" routerLink="/inquiry360/{{ data.data.idInquiry }}/{{ string_to_slug(data.data.name | ms) }}">
            <div class="col col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3">
              <hiji-visualize-campaign360-card [inquiry]="data.data"></hiji-visualize-campaign360-card>
            </div>
          </a>
        </div>

        <div class="row mt-2" *ngIf="data.data.constructor.name === 'VizInquiryAnswerDTO'">
          <a class="text-body" routerLink="/inquiry360/{{ data.data.idInquiry }}/{{ string_to_slug(data.data.name | ms) }}" *ngIf="data.data.assessor">
            <div class="col col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3 text-center">
              <hiji-visualize-sollicitation360-card [inquiryAnswer]="data.data"></hiji-visualize-sollicitation360-card>
            </div>
          </a>
          <a
            class="text-body"
            [routerLink]="[
              '/inquiry360/answer',
              data.data.idInquiry,
              string_to_slug(data.data.firstName + '-' + data.data.lastName),
              string_to_slug(data.data.name | ms),
            ]"
            *ngIf="!data.data.assessor"
          >
            <div class="col col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3 text-center">
              <hiji-visualize-sollicitation360-card [inquiryAnswer]="data.data"></hiji-visualize-sollicitation360-card>
            </div>
          </a>
        </div>
      </ng-container>

      <div class="row mt-4" *ngFor="let feedback of feedbacks | orderBy: 'feedback.creationDate' : true; let index = index">
        <div class="col col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3 pb-3">
          <hiji-feedback-message [feedback]="feedback"></hiji-feedback-message>
        </div>
      </div>

      <div class="row mt-2" *ngFor="let bilan of bilans | orderBy: 'bilan.comiteDate' : true">
        <a class="text-body" routerLink="/visualize/restitution/{{ bilan.idBilan }}">
          <div class="col col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3">
            <hiji-visualize-bilan-card [bilan]="bilan"></hiji-visualize-bilan-card>
          </div>
        </a>
      </div>
      <div class="row mt-2" *ngFor="let exchange of exchanges | orderBy: 'exchange.creationDate' : true">
        <a
          class="text-body"
          routerLink="/coaching/share/{{ exchange.idUserObserved }}/{{
            string_to_slug(exchange.idUserObserved === idUserLogged ? exchange.userManager : exchange.userObserved)
          }}/{{ exchange.idExchange }}"
        >
          <div class="col col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3">
            <hiji-visualize-exchange-card [exchange]="exchange"></hiji-visualize-exchange-card>
          </div>
        </a>
      </div>
      <div class="row mt-2" *ngFor="let survey of surveys | orderBy: 'survey.surveyResultsAvailableDate' : true">
        <a class="text-body" routerLink="/visualize/survey/{{ survey.idCampaign }}/{{ string_to_slug(survey.name | ms) }}">
          <div class="col col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3 text-center">
            <hiji-visualize-survey-card [survey]="survey"></hiji-visualize-survey-card>
          </div>
        </a>
      </div>

      <div class="row mt-2" *ngFor="let inquiry of inquiries | orderBy: 'inquiry.startDate' : true">
        <a class="text-body" routerLink="/inquiry360/{{ inquiry.idInquiry }}/{{ string_to_slug(inquiry.name | ms) }}">
          <div class="col col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3 text-center">
            <hiji-visualize-campaign360-card [inquiry]="inquiry"></hiji-visualize-campaign360-card>
          </div>
        </a>
      </div>

      <div class="row mt-2" *ngFor="let inquiryAnswer of inquiryAnswers | orderBy: 'inquiry.startDate' : true">
        <a
          class="text-body"
          routerLink="/inquiry360/{{ inquiryAnswer.idInquiry }}/{{ string_to_slug(inquiryAnswer.name | ms) }}"
          *ngIf="inquiryAnswer.assessor"
        >
          <div class="col col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3 text-center">
            <hiji-visualize-sollicitation360-card [inquiryAnswer]="inquiryAnswer"></hiji-visualize-sollicitation360-card>
          </div>
        </a>
        <a
          class="text-body"
          [routerLink]="[
            '/inquiry360/answer',
            inquiryAnswer.idInquiry,
            string_to_slug(inquiryAnswer.firstName + '-' + inquiryAnswer.lastName),
            string_to_slug(inquiryAnswer.name | ms),
          ]"
          *ngIf="!inquiryAnswer.assessor"
        >
          <div class="col col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3 text-center">
            <hiji-visualize-sollicitation360-card [inquiryAnswer]="inquiryAnswer"></hiji-visualize-sollicitation360-card>
          </div>
        </a>
      </div>

      <div class="row" *ngIf="loading">
        <div class="col-12 text-center">
          <i class="fa-light fa-spinner spinning"></i>
        </div>
      </div>

      <div class="row mt-3" *ngIf="nextMonthToLoad === null || !canLoadMore">
        <div class="col text-center my-fs-2">
          {{ "visualize.Fin de l'historique" | translate }}
        </div>
      </div>

      <div class="row mt-3" *ngIf="canLoadMore && affichage !== AffichageEnum.VOIR_TOUT">
        <div class="col text-center my-fs-2">
          <a class="text-dark text-decoration-underline" [routerLink]="null" (click)="loadMore()">
            {{ 'visualize.Charger plus' | translate }}
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid" id="menu">
    <div class="row justify-content-between bg-white flex-nowrap" style="overflow-x: auto; min-height: 40px">
      <div class="col item pointer d-flex flex-column justify-content-center" [class.active]="affichage === AffichageEnum.VOIR_TOUT">
        <a class="text-body fw-bolder" (click)="change(AffichageEnum.VOIR_TOUT)">
          <div class="text-center">
            <i class="fa-light fa-eye"></i>
          </div>
          <div class="text-center">{{ 'visualize.Voir tout' | translate }}</div>
        </a>
      </div>
      <div
        class="col item pointer d-flex flex-column justify-content-center"
        [class.active]="affichage === AffichageEnum.FEEDBACK"
        *ngIf="modulesDTO.moduleFBS.enabled"
      >
        <a class="text-body fw-bolder" routerLinkActive="active" (click)="change(AffichageEnum.FEEDBACK)">
          <div class="text-center">
            <i class="fa-question-circle fa-light"></i>
          </div>

          <div class="text-center">{{ 'visualize.Feedbacks' | translate }}</div>
        </a>
      </div>
      <div
        class="col item pointer d-flex flex-column justify-content-center"
        [class.active]="affichage === AffichageEnum.EXCHANGE"
        *ngIf="modulesDTO.module180.enabled"
      >
        <a class="text-body fw-bolder" routerLinkActive="active" (click)="change(AffichageEnum.EXCHANGE)">
          <div class="text-center">
            <i class="fa-light fa-comments"></i>
          </div>
          <div class="text-center">{{ 'visualize.Echanges' | translate }}</div>
        </a>
      </div>
      <div class="col item pointer d-flex flex-column justify-content-center" [class.active]="affichage === AffichageEnum.BILAN" *ngIf="modulesDTO.moduleBilan">
        <a class="text-body fw-bolder" routerLinkActive="active" (click)="change(AffichageEnum.BILAN)">
          <div class="text-center">
            <i class="fa-light fa-light fa-poll-h"></i>
          </div>
          <div class="text-center">{{ 'visualize.Restitutions' | translate }}</div>
        </a>
      </div>
      <div
        class="col item pointer d-flex flex-column justify-content-center"
        [class.active]="affichage === AffichageEnum.SURVEY"
        *ngIf="modulesDTO.moduleSurvey.enabled"
      >
        <a class="text-body fw-bolder pointer" routerLinkActive="active" (click)="change(AffichageEnum.SURVEY)">
          <div class="text-center">
            <i class="fa-light fa-poll fa-light"></i>
          </div>
          <div class="text-center">{{ 'visualize.Enquêtes' | translate }}</div>
        </a>
      </div>

      <div
        class="col item pointer d-flex flex-column justify-content-center"
        [class.active]="affichage === AffichageEnum.INQUIRY"
        *ngIf="modulesDTO.module360.enabled"
      >
        <a class="text-body fw-bolder" routerLinkActive="active" (click)="change(AffichageEnum.INQUIRY)">
          <div class="text-center">
            <i class="me-2 fa-light fa-clipboard-list"></i>
          </div>
          <div class="text-center">{{ 'visualize.Campagne 360' | translate }}</div>
        </a>
      </div>

      <div
        class="col item pointer d-flex flex-column justify-content-center"
        [class.active]="affichage === AffichageEnum.INQUIRYANSWERS"
        *ngIf="modulesDTO.module360.enabled"
      >
        <a class="text-body fw-bolder" routerLinkActive="active" (click)="change(AffichageEnum.INQUIRYANSWERS)">
          <div class="text-center">
            <i class="me-2 fa-light fa-clipboard-check"></i>
          </div>
          <div class="text-center">{{ 'visualize.Sollicitations 360' | translate }}</div>
        </a>
      </div>
    </div>
  </div>
</div>
