<div class="wrapper">
  <aside [class.show]="show">
    <div class="d-flex align-items-center bg-header p-1 pt-4 pb-3">
      <hiji-user-avatar
        style="min-width: 80px; min-height: 80px; width: 80px; height: 80px"
        [idUser]="idUser"
        [avatarExtension]="avatarExtension"
      ></hiji-user-avatar>
      <div class="ms-2">
        @if (authService.userBundle !== null) {
          <h5 class="mt-2">{{ authService.userBundle.user.firstName + ' ' + authService.userBundle.user.lastName }}</h5>
          <p *ngIf="modules.frontConfiguration.displayFunctionFront">{{ authService.userBundle.data.fonctionName }}</p>
        } @else {
          <h5 class="mt-2">{{ 'front-layout.Déconnecté' | translate }}</h5>
        }
      </div>
    </div>
    <nav>
      <h4 class="border-bottom border-white fw-normal">Menu</h4>
      <div class="d-flex flex-column">
        <a class="item" routerLinkActive="active" routerLink="/" [routerLinkActiveOptions]="{ exact: true }" (click)="hideMenu()">
          <div class="icon"><i class="fa-light fa-home"></i></div>
          <div>{{ 'menu.accueil' | translate | capitalize }}</div>
        </a>
        @if (authService.has(HijiRight.COACH)) {
          <a class="item" routerLinkActive="active" routerLink="/ema/coach/list" (click)="hideMenu()">
            <div class="icon"><i class="fa-light fa-comments-question-check"></i></div>
            @if (clientEnv.is(clientEnv.SKILLSUP)) {
              <div>{{ 'menu.specific.skillsup.Coachings' | translate | capitalize }}</div>
            } @else {
              <div>{{ 'menu.Coaching' | translate | capitalize }}</div>
            }
          </a>
        }
        <a class="item" routerLinkActive="active" routerLink="/formation/list" (click)="hideMenu()" *ngIf="modules.moduleMIF.enabled">
          <div class="icon"><i class="fa-light fa-graduation-cap"></i></div>
          <div>{{ 'menu.Formations' | translate | capitalize }}</div>
        </a>
        @if (modules.moduleEMA.teamKPIEnabled) {
          <a
            class="item"
            routerLinkActive="active"
            routerLink="/equipe-kpi"
            (click)="hideMenu()"
            *ngIf="modules.moduleEMA.enabled && modules.moduleEMA.hasTeam"
          >
            <div class="icon"><i class="fa-light fa-people-group"></i></div>
            <div>{{ 'menu.equipes KPI' | translate | capitalize }}</div>
          </a>
        }
        @if (clientEnv.is(clientEnv.SKILLSUP) && authService.userBundle !== null) {
          <a
            class="item"
            routerLinkActive="active"
            routerLink="skillsup/coaching/{{ idUser }}/{{ string_to_slug(authService.userBundle.user.firstName + '-' + authService.userBundle.user.lastName) }}"
            (click)="hideMenu()"
            *ngIf="modules.moduleCertification"
          >
            <div class="icon"><i class="fa-light fa-certificate"></i></div>
            <div>{{ 'menu.specific.skillsup.Coachings' | translate | capitalize }}</div>
          </a>
        }
        <a class="item" routerLinkActive="active" routerLink="/certification" (click)="hideMenu()" *ngIf="modules.moduleCertification">
          <div class="icon"><i class="fa-light fa-certificate"></i></div>
          @if (clientEnv.is(clientEnv.SKILLSUP)) {
            <div>{{ 'menu.specific.skillsup.Assessments' | translate | capitalize }}</div>
          } @else {
            <div>{{ 'menu.Certification' | translate | capitalize }}</div>
          }
        </a>

        <a class="item" routerLinkActive="active" routerLink="/discover" (click)="hideMenu()" *ngIf="!hideDiscover">
          <div class="icon"><i class="fa-light fa-play-circle"></i></div>
          <div>{{ 'menu.découvrir' | translate | capitalize }}</div>
        </a>
        <a class="item" routerLinkActive="active" routerLink="/progress" (click)="hideMenu()" *ngIf="!hideProgress">
          <div class="icon"><i class="fa-light fa-chart-line"></i></div>
          <div>{{ 'menu.progresser' | translate | capitalize }}</div>
        </a>
        <a class="item" routerLinkActive="active" routerLink="/visualize" (click)="hideMenu()" *ngIf="!hideVisualize">
          <div class="icon"><i class="fa-light fa-eye"></i></div>
          <div>{{ 'menu.visualiser' | translate | capitalize }}</div>
        </a>
        <a class="mini-item mt-4" routerLinkActive="active" routerLink="/my-profile" (click)="hideMenu()">
          <div class="icon"><i class="fa-light fa-user"></i></div>
          <div>{{ 'menu.profil' | translate | capitalize }}</div>
        </a>
        <a class="mini-item" routerLinkActive="active" routerLink="/gdpr" (click)="hideMenu()">
          <div class="icon"><i class="fa-light fa-shield-alt"></i></div>
          <div>{{ 'menu.Politique de confidentialité' | translate | capitalize }}</div>
        </a>
        <a class="mini-item" href="{{ boUrl }}" (click)="hideMenu()" *ngIf="canSeeBackoffice">
          <div class="icon"><i class="fa-light fa-cog"></i></div>
          <div>{{ 'menu.backoffice' | translate | capitalize }}</div>
        </a>
        <a class="mini-item" routerLinkActive="active" routerLink="/support" (click)="hideMenu()">
          <div class="icon"><i class="fa-light fa-headset"></i></div>
          <div>{{ 'menu.support' | translate | capitalize }}</div>
        </a>
        <a class="mini-item pointer d-flex" (click)="logout()">
          <div class="icon"><i class="fa-light fa-sign-out-alt"></i></div>
          {{ 'menu.déconnexion' | translate | capitalize }}
        </a>
        <a
          class="mini-item pointer d-flex"
          routerLinkActive="active"
          routerLink="/test"
          *ngIf="authService.isUser('maximilien@hiji.fr') || clientEnv.isDeveloppmentEnv() || clientEnv.isRecetteEnv()"
        >
          <div class="icon"><i class="fa-light fa-ticket"></i></div>
          Test & Charte
        </a>
      </div>
    </nav>
  </aside>
  <div class="d-flex flex-column flex-grow-1">
    <div class="menu-button text-white" (click)="toggleMenu()">
      <i class="fa-light fa-bars"></i>
    </div>
    <header>
      <div class="back-link d-flex align-items-center h-100 text-white ps-2 pe-2 pointer user-select-none" [class.visible]="showBackLink" back>
        <i class="fa-light fa-chevron-left me-lg-2"></i>
        <span class="d-none d-lg-inline">{{ 'global.Retour' | translate }}</span>
      </div>
      <h3>
        <i [ngClass]="'me-2 ' + titleIconClass" *ngIf="titleIconClass !== null"></i>
        <img style="height: 20px" [src]="titleImg" *ngIf="titleImg !== null" />
        <span>{{ title | translate | capitalize }}</span>
      </h3>
      <div class="d-flex align-items-center">
        <div class="theme-button pointer px-2 py-1 me-2" (click)="toggleDarkMode()">
          <i class="fa-light" [class.fa-moon]="isDarkMode" [class.fa-sun]="!isDarkMode"></i>
        </div>
        <div class="menu-language me-2 px-20 py-20" ngbDropdown placement="bottom-right" *ngIf="showFrontLanguageChange">
          <div class="pointer" ngbDropdownToggle>
            {{ currentLang }}
          </div>
          <div ngbDropdownMenu>
            <h6 class="dropdown-header">{{ 'global.Langue' | translate }}</h6>
            <div style="max-height: 200px; overflow: auto">
              <ng-container *ngFor="let lang of sortedAvailableLanguages">
                <div ngbDropdownItem *ngIf="lang !== currentLang">
                  <div>
                    <button [class.text-success]="translate.currentLang == lang" ngbDropdownItem (click)="changeLanguage(lang)">
                      {{ 'common.languages.' + lang | translate }}
                    </button>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </header>
    <main (click)="hideMenu()">
      <router-outlet></router-outlet>
      <ic2-toastr-container class="position-fixed bottom-0 start-50 translate-middle-x"></ic2-toastr-container>
    </main>
  </div>
</div>
