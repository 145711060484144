import { Component, OnInit } from '@angular/core';
import { AuthService } from 'common';

@Component({
  selector: 'hiji-search-card',
  templateUrl: './search-card.component.html',
  styleUrls: ['./search-card.component.scss'],
})
export class SearchCardComponent implements OnInit {
  manualHierarchicalManagement: boolean = false;
  constructor(private authService: AuthService) {
    if (this.authService.userBundle) this.manualHierarchicalManagement = this.authService.modules.moduleGlobal.manualHierarchicalManagement;
  }

  ngOnInit(): void {}
}
