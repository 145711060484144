import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EnvService, L, string_to_slug } from '@ic2/ic2-lib';
import { TranslateService } from '@ngx-translate/core';
import {
  AuthService,
  Config,
  HijiBundle,
  Ic2ToastrService,
  ModulesDTO,
  UserActionsDTO,
  UserActionsService,
  UserFuncAvatarDTO,
  UserRelationService,
} from 'common';
import { FeedbackData } from '../fbs/feedback.data';

@Component({
  selector: 'hiji-user-actions',
  templateUrl: './user-actions.component.html',
  styleUrls: ['./user-actions.component.scss'],
})
export class UserActionsComponent implements OnInit {
  idUser: number;
  data: UserActionsDTO;
  loading: boolean = true;
  loadingTeamMember: boolean = false;
  me: boolean = false;
  string_to_slug = string_to_slug;
  messageFailedToDelete: string = null;
  modulesDTO: ModulesDTO;
  idUserLogged: number;
  hideVisualize: boolean = false;

  constructor(
    private userActionsService: UserActionsService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private userRelationService: UserRelationService,
    private feedbackData: FeedbackData,
    private ic2ToastrService: Ic2ToastrService,
    private translate: TranslateService,
    public envService: EnvService<Config>
  ) {
    this.modulesDTO = (this.authService.userBundle.data as HijiBundle).modules;
    this.hideVisualize = this.modulesDTO.frontConfiguration.hideVisualizeMenu ?? false;
  }

  ngOnInit(): void {
    this.idUserLogged = this.authService.userBundle.user.idUser;
    this.route.params.subscribe((params) => {
      this.loading = true;
      this.idUser = +params['idUser'];
      if (this.idUser === this.authService.userBundle.user.idUser) this.me = true;
      this.userActionsService.getActions(this.idUser).subscribe(
        (data) => {
          this.data = data;
          this.loading = false;
        },
        (error) => {
          if (error.isBusinessError() && error.data && error.data['code'] === 'no_common_enseigne')
            this.ic2ToastrService.show(
              this.translate.instant("user-actions.Vous n'appartenez pas à une enseigne commune avec cet utilisateur, contactez l'assistance"),
              {
                classname: 'bg-warning text-white',
              }
            );
          L.e(error);
          this.router.navigate(['/']);
          this.loading = false;
        }
      );
    });
  }

  sendFBS() {
    const u = new UserFuncAvatarDTO();
    u.idUser = this.data.member.idUser;
    u.avatarExtension = this.data.member.avatarExtension;
    u.firstName = this.data.member.firstName;
    u.lastName = this.data.member.lastName;
    u.functionName = this.data.member.functionName;
    this.feedbackData.reset();
    this.feedbackData.users = [u];
    this.router.navigate(['/feedback/new']);
  }

  askFBS() {
    const u = new UserFuncAvatarDTO();
    u.idUser = this.data.member.idUser;
    u.avatarExtension = this.data.member.avatarExtension;
    u.firstName = this.data.member.firstName;
    u.lastName = this.data.member.lastName;
    u.functionName = this.data.member.functionName;
    this.feedbackData.reset();
    this.feedbackData.users = [u];
    this.router.navigate(['/feedback/ask']);
  }

  cancelInvitationUserInMyTeam() {
    this.loadingTeamMember = true;
    this.userRelationService
      .cancelInvitationUserInMyTeam(this.idUser)
      .onBusinessErrorCode('no_user_delete_rh', () => {
        this.messageFailedToDelete = this.translate.instant(
          'user-actions.Vous ne pouvez pas retirer cette personne de votre équipe car votre logiciel RH vous définit comme son manager'
        );
      })
      .onBusinessErrorCode('no_user_delete_team_share', () => {
        this.messageFailedToDelete = this.translate.instant(
          "user-actions.Vous ne pouvez pas retirer cette personne de votre équipe car elle vous est partagée de l'équipe d'un autre manager, contactez l'assistance"
        );
      })
      .onOtherErrorTypesUseDefault()
      .onErrorAlwaysDo(() => {
        this.loadingTeamMember = false;
      })
      .execute((data) => {
        this.loadingTeamMember = false;
        this.data.isTeamMemberInvitationPending = false;
      });
  }

  inviteUserInMyTeam() {
    this.loadingTeamMember = true;
    this.userRelationService
      .inviteUserInMyTeam(this.idUser)
      .onBusinessErrorCode('no_user_delete_rh', () => {
        this.messageFailedToDelete = this.translate.instant(
          'user-actions.Vous ne pouvez pas retirer cette personne de votre équipe car votre logiciel RH vous définit comme son manager'
        );
      })
      .onBusinessErrorCode('no_user_delete_team_share', () => {
        this.messageFailedToDelete = this.translate.instant(
          "user-actions.Vous ne pouvez pas retirer cette personne de votre équipe car elle vous est partagée de l'équipe d'un autre manager, contactez l'assistance"
        );
      })
      .onOtherErrorTypesUseDefault()
      .onErrorAlwaysDo(() => {
        this.loadingTeamMember = false;
      })
      .execute((data) => {
        this.loadingTeamMember = false;
        this.data.isTeamMemberInvitationPending = true;
      });
  }

  removeUserFromMyTeam() {
    this.loadingTeamMember = true;
    this.userRelationService
      .removeUserFromMyTeam(this.idUser)
      .onBusinessErrorCode('no_user_delete_rh', () => {
        this.messageFailedToDelete = this.translate.instant(
          'user-actions.Vous ne pouvez pas retirer cette personne de votre équipe car votre logiciel RH vous définit comme son manager'
        );
      })
      .onBusinessErrorCode('no_user_delete_team_share', () => {
        this.messageFailedToDelete = this.translate.instant(
          "user-actions.Vous ne pouvez pas retirer cette personne de votre équipe car elle vous est partagée de l'équipe d'un autre manager, contactez l'assistance"
        );
      })
      .onOtherErrorTypesUseDefault()
      .onErrorAlwaysDo(() => {
        this.loadingTeamMember = false;
      })
      .execute((data) => {
        this.loadingTeamMember = false;
        this.data.isInMyTeam = false;
      });
  }
}
